import "@ethersproject/shims"
import { BigNumber, ethers } from "ethers";
import { getContractObj } from ".";
import { NFTMintEngineDetail } from "./typs";

export async function purchase(chainId, provider, numberOfTokens) {
    const CalabasasKidClubContract = getContractObj('CalabasasKidClub', chainId, provider);
    try {
        const nftPrice: BigNumber = await CalabasasKidClubContract.PRICE();
        const tx = await CalabasasKidClubContract.purchase(numberOfTokens, {
            value: nftPrice.mul(numberOfTokens)
        });
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function getEngineInfo(chainId, library) {
    const CalabasasKidClubContract = getContractObj('CalabasasKidClub', chainId, library);
    try {
        const [
            totalSupply,
            maxSupply,
            mintPrice,
            purchaseLimit,
        ] = await Promise.all([
            CalabasasKidClubContract.totalSupply(),
            CalabasasKidClubContract.CKC_MAX(),
            CalabasasKidClubContract.PRICE(),
            CalabasasKidClubContract.PURCHASE_LIMIT(),
        ]);

        const nftMintDetail: NFTMintEngineDetail = {
            totalSupply: parseInt(totalSupply.toString()),
            maxSupply: parseInt(maxSupply.toString()),
            mintPrice: parseFloat(ethers.utils.formatEther(mintPrice)),
            purchaseLimit: parseInt(purchaseLimit.toString()),
        }

        return nftMintDetail;
    } catch (e) {
        console.log(e);
        return null;
    }
}