import { useEffect, useState } from "react";
import "./header.style.css";
import { Button } from "@material-ui/core";
import logo from "../../imgs/newLogo.jpg";
import HeaderItem from "../HeaderItem/HeaderItem.component";
import { FaTwitter } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import { RiMenu2Fill } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";

import useAuth from "hooks/useAuth";
import { useWeb3React } from "@web3-react/core";
import { truncateWalletString } from "utils";

import styles from "../../utils/styles";

interface Props {}

const Header: React.FC<Props> = () => {
  const [open, setOpen] = useState(false);
  console.log({ open });

  const { login } = useAuth();
  const [loginStatus, setLoginStatus] = useState(false);
  const { connector, library, chainId, account, active } = useWeb3React();
  useEffect(() => {
    const isLoggedin = account && active && chainId === parseInt(process.env.REACT_APP_NETWORK_ID, 10);
    if (isLoggedin) {
    }
    setLoginStatus(isLoggedin);
  }, [connector, library, account, active, chainId]);

  const loginMetaMask = () => {
    login(1);
  };

  return (
    <div>
      <div className="header">
        <div className="header_Left">
          <img src={logo} alt="logo" className="header_logo" />

          <div className="header_Items">
            <div style={{ marginRight: "1rem" }}>
              <HeaderItem
                text="Twitter"
                icon={<FaTwitter style={{ marginRight: "10px" }} />}
                herf="https://twitter.com/CalabasasKidNFT"
              />
            </div>
            <div style={{ marginLeft: "1.5rem" }}>
              <HeaderItem
                text="Discord"
                icon={<FaDiscord style={{ marginRight: "10px" }} />}
                herf="https://discord.gg/4zCpY3qBTB"
              />
            </div>
          </div>
        </div>

        <Button
          variant="contained"
          style={{
            background: styles.colors.primary,
            color: "white",
            textTransform: "capitalize",
            height: "2.5rem",
          }}
        >
          {loginStatus ? truncateWalletString(account) : "Connect Wallet"}
        </Button>
      </div>

      <div className="menu_respon">
        <button className="menu_btn" onClick={() => setOpen(!open)}>
          {open ? <AiOutlineClose /> : <RiMenu2Fill />}
        </button>

        <Button
          variant="contained"
          style={{
            background: styles.colors.primary,
            color: "white",
            textTransform: "capitalize",
            height: "2.5rem",
          }}
        >
          Connect Wallet
        </Button>
      </div>
      <div className={open ? "nav active" : "nav"}>
        <HeaderItem
          text="Twitter"
          icon={<FaTwitter style={{ marginRight: "10px" }} />}
          herf="https://twitter.com/CalabasasKidNFT"
        />
        <div style={{ marginTop: "1rem" }}>
          <HeaderItem
            text="Discord"
            icon={<FaDiscord style={{ marginRight: "10px" }} />}
            herf="https://discord.gg/4zCpY3qBTB"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
