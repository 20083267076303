import React from "react";
import "./App.css";
import Header from "./components/Header/Header.component";
import Gif_Container from "./components/Gif_Container/Gif_Container.component";
import Intro from "./components/Intro/Intro.component";
import PraragraphContainer from "./components/PraragraphContainer/PraragraphContainer.component";
import CKC from "./components/CKC/CKC.compnent";
import RoadMap from "./components/RoadMap/RoadMap.component";
import Footer from "./components/Footer/Footer.component";
import banner from "./imgs/banner.png";
import { Toaster } from "react-hot-toast";
import { useEagerConnect } from "hooks/useEagerConnect";

function App() {
  useEagerConnect();

  return (
    <>
      <Toaster
        position="top-center"
        toastOptions={{
          success: { duration: 3000 },
          error: { duration: 3000 },
        }}
      />
      <div className="App">
        <Header />
        <div className="PraragraphContainer">
          <Gif_Container />
          <div className="app_intro">
            <Intro />
          </div>
          <PraragraphContainer
            title="WHAT MAKE CALABASAS KID CLUB DIFFERENT?"
            paragraph="Calabasas Kid Club is an original NFT Collection. Our team’s focus is to create a
            hypebeast community with all things collectibles and a CKC focused fashion brand."
          />
          <PraragraphContainer
            title="Price"
            paragraph="Every Calabasas Kid costs 0.05 ETH and the max cap per mint is 20."
          />
          <PraragraphContainer
            title="WHEN WILL THEY BE REVEALED?"
            paragraph="Once you mint a CKC and they show up in your Opensea account, the image will be
            revealed within a few minutes. And if they don’t show up right away, don’t worry! Try
            refreshing the metadata and page and wait a little while longer."
          />
          <PraragraphContainer
            title="WHAT IS A CHARACTER KID?"
            paragraph="Character Kids are special edition kids that are based off characters from movies,
            anime, musicians, comics, etc.. All of them have been suggested by members in the
            community and can be found randomly within the collection. Out of the 5k Kids, there
            will only be 14 with the “Character Kid” trait.
            To view the collection of Character Kids that will be featured in this collection, make
            sure to join the Discord and check out the Sneak Peak channel!."
          />
          {/* <RoadMap /> */}
          <CKC />
          <PraragraphContainer
            title="WHO IS THE KID"
            paragraph="THE KID is an alias used for the creator of Calabasas Kid Club. She grew up in Los
            Angeles County, and later worked in Calabasas as a personal assistant to a well-known
            family in the entertainment industry. This collection was originally inspired by her take
            on the kids of Calabasas who tend to have all the high-end luxuries and products
            everyone wishes they had."
          />
          <Footer />
          {/* <Timer mintStartAt={1631138400} /> */}
        </div>
      </div>
    </>
  );
}

export default App;
