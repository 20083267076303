import { Button } from "@material-ui/core";
import gif from "../../imgs/mainGif.gif";
import styles from "../../utils/styles";

import useAuth from "hooks/useAuth";
import { useWeb3React } from "@web3-react/core";
import { truncateWalletString } from "utils";
import { useEffect, useState } from "react";  
import Mint from "components/Mint/Mint";


interface Props {}

const Gif_Container: React.FC<Props> = ({}) => {
  const { login } = useAuth();
  const [loginStatus, setLoginStatus] = useState(false);
  const { connector, library, chainId, account, active } = useWeb3React();
  useEffect(() => {
    const isLoggedin =
      account &&
      active &&
      chainId === parseInt(process.env.REACT_APP_NETWORK_ID, 10);
    if (isLoggedin) {
    }
    setLoginStatus(isLoggedin);
  }, [connector, library, account, active, chainId]);

  const loginMetaMask = () => {
    login(1);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "auto",
        marginBottom: "5rem ",
      }}
    >
      <Mint />
      <Button
        variant="contained"
        style={{
          background: styles.colors.primary,
          color: "white",
          textTransform: "capitalize",
          height: "2.5rem",
          opacity: ".6",
          width: "320px",
          marginTop: "10px",
        }}
      >
        {loginStatus ? truncateWalletString(account) : "Connect Wallet"}
      </Button>
    </div>
  );
};

export default Gif_Container;
