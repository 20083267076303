import styles from "../../utils/styles";
import "./ckc.style.css";

interface Props {
  //   title: String;
  //   paragraph: String;
}

const CKC: React.FC<Props> = ({}) => {
  return (
    <div
      style={{
        background: `${styles.colors.bg}`,
        width: "100%",
        margin: "auto",
      }}
    >
      <h2 className="m-0 p-0">WHAT CAN I EXPECT FROM CKC?</h2>
      {/* <h2 className="m-0 p-0 ckc_title">Before Mint</h2> */}

      <p className="m-0 p-0 p_title">Before Mint</p>
      <p className="m-0 p-0">⁃ Twitter Giveaway</p>
      <p className="m-0 p-0">⁃ POAP Raffle / Giveaway</p>
      <br />
      <p className="m-0 p-0 p_title">25% sold (1,250)</p>
      <p className="m-0 p-0">⁃ Comic Book (1st Issue)</p>
      <p className="m-0 p-0">⁃ CKC Shoe Giveaway</p>
      <br />
      <p className="m-0 p-0 p_title">50% sold (2,500) </p>
      <p className="m-0 p-0">⁃ Comic Book (2nd Issue)</p>
      <p className="m-0 p-0">⁃ Community Designed CKC Shoe</p>
      <br />
      <p className="m-0 p-0 p_title">75% sold (3,750)</p>
      <p className="m-0 p-0">⁃ Basic Merch</p>
      <p className="m-0 p-0">⁃ Limited Edition Custom “THE KID” Chains </p>
      <p className="m-0 p-0">⁃ Comic Book (3rd Issue)</p>
      <br />
      <p className="m-0 p-0 p_title">100% sold (5,000)</p>
      <p className="m-0 p-0">⁃ Limited Edition 3d Resin Figures</p>
      <p className="m-0 p-0">⁃ Limited Edition Skateboards</p>
      <p className="m-0 p-0">⁃ Limited Edition Rugs</p>
      <p className="m-0 p-0">
        ⁃ Comic book (4th & 5th Issue) + Physical Copy of all Issues
      </p>
      <p className="m-0 p-0">⁃ Animated Video with Kids</p>
      <p className="m-0 p-0">⁃ Sandbox Calabasas Land</p>
    </div>
  );
};

export default CKC;
